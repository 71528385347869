import React from "react";

const Index = ({ dataPumma }) => {
  return (
    <div className="flex flex-col overflow-hidden over">
      <div className="overflow-x-scroll">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className=" border rounded-lg">
            <table className="min-w-full bg-white divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-sm font-bold text-left text-gray-500 capitalize "
                  >
                    No
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    WaterLevel
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    voltage
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    temperature
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    threshold
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    forecast30
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    forecast300
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-center text-gray-500 capitalize "
                  >
                    Rms
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {dataPumma?.result.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-6 py-3 text-xs font-medium text-gray-800 whitespace-nowrap">
                        {i + 1}
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.time}
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.waterlevel}
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.voltage}
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.temperature}
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.threshold}
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.forecast30} cm
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 whitespace-nowrap">
                        {item.forecast300} cm
                      </td>
                      <td className="px-6 py-3 text-xs text-gray-800 text-center whitespace-nowrap">
                        {item.rms}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
                {/* <div className="">1</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
