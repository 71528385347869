import React from "react";
import { TbCloudDownload } from "react-icons/tb";
import { Batteryvoltage, temperature, waterLevel } from "../../assets";
import { Chart, Selected, SimpleMap, Tabel } from "../../component/molecules";
import GetDataByeLengt from "../../utils/dataApi/GetDataByeLengt";
import GetDataImage from "../../utils/dataApi/GetDataImage";
import GetdataPummaCanti from "../../utils/dataApi/GetdataPummaCanti";
import MQTT from "../../utils/mqtt/MQTT";
import CartTigaData from "../../component/molecules/Chart/CartTigaData";

const Index = ({
  datarealtime,
  dataTable,
  dataimg,
  maps,
  dataGrafik,
  setDataGrafik,
  location,
}) => {
  const { dataone } = MQTT(datarealtime);
  const { dataPumma } = GetdataPummaCanti(dataTable);
  const { dataImage } = GetDataImage(dataimg);
  const { data } = GetDataByeLengt(location, dataGrafik);

  return (
    <div className="gap-5 pb-10">
      <div className="space-y-7 sm:grid sm:grid-cols-2 sm:gap-5  sm:space-y-0  md:justify-start md:gap-5 md:flex md:flex-wrap">
        <div className={`px-3 py-3  flex items-center rounded-sm min-w-[30%] ${dataone && parseInt(dataone.tegangan) < 12 ? "bg-[#00FB64]" : "bg-[#00FB64]"}`}>
          <div className="w-full">
            <div className="flex space-x-4 items-center">
              <p className="font-medium text-base">Battery Voltage</p>
              <p>
                <TbCloudDownload />
              </p>
            </div>
            <div className="text-3xl font-semibold justify-between flex space-x-5 items-center">
              <p> 12.19 V</p>
              <img src={Batteryvoltage} alt="" className="bg-contain w-16" />
            </div>
          </div>
        </div>

        <div className={`px-3 py-3  flex items-center rounded-sm min-w-[30%] ${dataone && parseInt(dataone.suhu) < 70 ? "bg-[#00FB64]" : "text-white bg-red-900"}`}>
          <div className="w-full">
            <div className="flex space-x-4 items-center">
              <p className="font-medium text-base"> Device Temperature</p>
              <p>
                <TbCloudDownload />
              </p>
            </div>
            <div className="text-3xl font-semibold justify-between flex space-x-5 items-center">
              <p>{dataone ? dataone.suhu : ""} °C</p>
              <img src={temperature} alt="" className="bg-contain w-16" />
            </div>
          </div>
        </div>

        <div className="bg-[#00FB64] px-3 py-3  flex items-center rounded-sm min-w-[30%] ">
          <div className="w-full">
            <div className="flex space-x-4 items-center">
              <p className="font-medium text-base">Sea Water Level</p>
              <p>
                <TbCloudDownload />
              </p>
            </div>
            <div className="text-3xl font-semibold justify-between flex space-x-5 items-center">
              <p>{dataone ? dataone.tinggi : ""} cm</p>
              <img src={waterLevel} alt="" className="bg-contain w-16" />
            </div>
          </div>
        </div>

        <div className="bg-[#00FB64] px-3 py-3  flex items-center rounded-sm min-w-[30%] ">
          <div className="w-full">
            <div className="flex space-x-4 items-center">
              <p className="font-medium text-base">Forcast30</p>
              <p>
                <TbCloudDownload />
              </p>
            </div>
            <div className="text-3xl font-semibold justify-between flex space-x-5 items-center">
              <p>{dataone ? dataone.frcst30 : ""} cm</p>
              <img src={waterLevel} alt="" className="bg-contain w-16" />
            </div>
          </div>
        </div>

        <div className="bg-[#00FB64] px-3 py-3  flex items-center rounded-sm min-w-[30%] ">
          <div className="w-full">
            <div className="flex space-x-4 items-center">
              <p className="font-medium text-base">Forcast300</p>
              <p>
                <TbCloudDownload />
              </p>
            </div>
            <div className="text-3xl font-semibold justify-between flex space-x-5 items-center">
              <p>{dataone ? dataone.frcst300 : ""} cm</p>
              <img src={waterLevel} alt="" className="bg-contain w-16" />
            </div>
          </div>
        </div>

        <div className={`px-3 py-3  flex items-center rounded-sm min-w-[30%] ${dataone && dataone.status === "SAFE" ? "bg-[#00FB64]" : "text-white bg-red-900"}`}>
          <div className="w-full">
            <div className="flex space-x-4 items-center">
              <p className="font-medium text-base">Status</p>
              <p>
                <TbCloudDownload />
              </p>
            </div>
            <div className="text-3xl font-semibold justify-between flex space-x-5 items-center">
              <p>{dataone ? dataone.status : ""}</p>
              <img src={waterLevel} alt="" className="bg-contain w-16" />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-10">
        <p className="font-semibold text-base">TABEL</p>
      </div>
      <div className="grid ">
        <Tabel dataPumma={dataPumma ? dataPumma : dataPumma} />
      </div>
      <div className="pt-10">
        <p className="font-semibold text-base">GRAFIK</p>
      </div>
      <div>
        <Selected setDataGrafik={setDataGrafik} dataGrafik={dataGrafik} />
      </div>
      <div className="grid md:grid-cols-2 md:gap-5">
        <div className=" px-3 py-3  flex items-center rounded-sm">
          <div aria-label="waterlevel" className="w-full">
            <CartTigaData
              DataLine={data?.result}
              name={"waterlevel"}
              labelname1={"WaterLevel"}
              borderColor1={"rgb(0,0,0,0,0)"}
              bgcolor1={"rgb(0,0,0,0,0)"}
              index1={1}
              labelname2={"frc30"}
              borderColor2={"rgb(128,0,0)"}
              bgcolor2={"rgb(128,0,0)"}
              index2={4}
              labelname3={"frc300"}
              borderColor3={"rgb(160,82,45)"}
              bgcolor3={"rgb(160,82,45)"}
              index3={5}
            />
          </div>
        </div>
        <div className=" px-3 py-3  flex items-center rounded-sm">
          <div aria-label="waterlevel" className="w-full">
            <CartTigaData
              DataLine={data?.result}
              name={"alert level"}
              labelname1={"alertlevel"}
              borderColor1={"rgb(0,0,0,0,0)"}
              bgcolor1={"rgb(0,0,0,0,0)"}
              index1={8}
              labelname2={"rms"}
              borderColor2={"rgb(0,0,255)"}
              bgcolor2={"rgb(0,0,255)"}
              index2={6}
              labelname3={"threshold"}
              borderColor3={"rgb(128,0,0)"}
              bgcolor3={"rgb(128,0,0)"}
              index3={7}
            />
          </div>
        </div>
        {/* <div className=" px-3 py-3  flex items-center rounded-sm">
          <div aria-label="waterlevel" className="w-full">
            <Chart
              DataLine={data?.result}
              name={"waterlevel"}
              labelname={"WaterLevel"}
              borderColor={"rgb(100,149,237)"}
              bgcolor={"rgb(100,149,237)"}
              index={1}
            />
          </div>
        </div> */}
        <div className=" px-3 py-3  flex items-center rounded-sm">
          <div aria-label="voltage" className="w-full">
            <Chart
              DataLine={data?.result}
              name={"voltage v"}
              labelname={"voltage"}
              borderColor={"rgb(0,128,128)"}
              bgcolor={"rgb(0,128,128)"}
              index={2}
            />
          </div>
        </div>
        <div className=" px-3 py-3  flex items-center rounded-sm">
          <div aria-label="temperature" className="w-full">
            <Chart
              DataLine={data?.result}
              name={"temperature  °C"}
              labelname={"temperature"}
              borderColor={"rgb(0,0,128)"}
              bgcolor={"rgb(0,0,128)"}
              index={3}
            />
          </div>
        </div>
        <div className=" px-3 py-3 w-full  flex items-center rounded-sm">
          <div className="w-[100%] h-[100%] overflow-hidden">
            <SimpleMap maps={maps} />
          </div>
        </div>
        <div className=" px-3 py-3  flex items-center rounded-sm">
          <div className="w-full ">
            <img src={dataImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
