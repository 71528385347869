import React from "react"; // eslint-disable-next-line
import Chart from "chart.js/auto"; // eslint-disable-next-line
import { Line } from "react-chartjs-2";
import { time } from "../../../utils/index";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
Chart.register(zoomPlugin);

const CartTigaData = ({
  DataLine,
  labelname1,
  borderColor1,
  bgcolor1,
  index1,
  labelname2,
  borderColor2,
  bgcolor2,
  index2,
  labelname3,
  borderColor3,
  bgcolor3,
  index3,
}) => {
  const DataChart = {
    type: "line",
    labels: DataLine?.map((item, i) => {
      const { times } = time(item[0]);
      return times;
    }),
    label : "nilai" ,
    datasets: [
      {
        label: labelname1,
        backgroundColor: bgcolor1,
        borderColor: borderColor1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        data: DataLine && DataLine?.map((data) => data[index1]),
        // spanGaps: true,
      },
      {
        label: labelname2,
        backgroundColor: bgcolor2,
        borderColor: borderColor2,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        data: DataLine && DataLine?.map((data) => data[index2]),
        // spanGaps: true,
      },
      {
        label: labelname3,
        backgroundColor: bgcolor3,
        borderColor: borderColor3,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        data: DataLine && DataLine?.map((data) => data[index3]),
        // spanGaps: true,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text : "time"
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "water height cm"
        },
      }
    },
    responsive: true,
    legend: {
       display: true,
    },
    tooltips: {
       mode: "index",
       intersect: false,
    },
    hover: {
       mode: "nearest",
       intersect: true
    },
    animation: {
      duration: 0,
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
          },
          mode: "x",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "x",
          speed: 0.5,
        },
      },
      animations: false,    
      tooltip: {
        mode: "index",
        intersect: true,
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div>
      <Line
        options={options}
        data={DataChart}
        className={`${DataLine ? "" : "animate-pulse"}`}
      />
    </div>
  );
};

export default CartTigaData;
