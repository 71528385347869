import React from "react";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { FaWater } from "react-icons/fa";

const Index = ({ isFalse, setIsfalse, setBurgers, setBurger }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  const handleclikHome = () => {
    navigate("/");
    if(window.innerWidth < 640){
      setBurger(false)
      setBurgers(false)
    }
  }
  const handleclikPumma = () => {
    navigate("/pumma");
    if(window.innerWidth < 640){
      setBurger(false)
      setBurgers(false)
    }
  }
  const handleclikClimatologi = () => {
    navigate("/climatologi");
    if(window.innerWidth < 640){
      setBurger(false)
      setBurgers(false)
    }
  }
  return (
    <div className="text-xs w-full font-medium">
      <div
        onClick={handleclikHome}
        className="w-full cursor-pointer h-12 flex justify-center items-center border-b-2 border-slate-400"
      >
        <FaWater
          className={`bg-cover ${isFalse === false ? "text-3xl" : "text-2xl"} `}
        />
      </div>

      {/* menu sidebar */}
      <div className="px-3">
        <div
          onClick={handleclikPumma}
          className={`flex cursor-pointer  h-12 justify-between items-center  ${
            pathname === "/pumma" || pathname === "/pummaCanti"
              ? " text-blue-600 "
              : ""
          } `}
        >
          <div
            className={`flex space-x-2 items-center  w-full ${
              isFalse === false ? "justify-start" : "justify-center"
            }`}
          >
            <MdOutlineDashboardCustomize
              className={`${isFalse === false ? "text-lg" : "text-2xl"}`}
            />
            <h1
              className={`${isFalse === true ? "scale-0 hidden" : "scale-100"}`}
            >
              Pumma
            </h1>
          </div>
        </div>
        <div
          onClick={handleclikClimatologi}
          className={`flex cursor-pointer  h-12 justify-between items-center  ${
            pathname === "/climatologi" ? " text-blue-600 " : ""
          } `}
        >
          <div
            className={`flex space-x-2 items-center  w-full ${
              isFalse === false ? "justify-start" : "justify-center"
            }`}
          >
            <MdOutlineDashboardCustomize
              className={`${isFalse === false ? "text-lg" : "text-2xl"}`}
            />
            <h1
              className={`${isFalse === true ? "scale-0 hidden" : "scale-100"}`}
            >
              Climatologi
            </h1>
          </div>
        </div>
        <div
          className={` py-5 cursor-pointer justify-between items-center ${
            isFalse === true ? "relative block " : ""
          }`}
        ></div>
      </div>
    </div>
  );
};

export default Index;
