import React from "react";
import { useNavigate } from "react-router";
import Hooks from "./hook";

const Index = () => {
  const navigate = useNavigate();
  const { dataPumma, data } = Hooks();

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-x-scroll border rounded-lg">
            <table className="min-w-full bg-white divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-sm font-bold text-left text-gray-500 capitalize "
                  >
                    ID
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Sensor
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Last Value
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 capitalize "
                  >
                    Last Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-center text-gray-500 capitalize "
                  >
                    Elapsed Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-center text-gray-500 capitalize "
                  >
                    Sensor time to server
                  </th>
                </tr>
              </thead>
              <tbody className={`divide-y divide-gray-200 ${data ? '' : 'animate-pulse'}`}>
                {dataPumma?.map((item, i) => {
                  return (
                    <tr>
                      <td className="px-6 py-4 text-xs font-medium text-gray-800 whitespace-nowrap">
                        {i + 1}
                      </td>
                      <td
                        onClick={() => {
                          navigate(item.link);
                        }}
                        className="px-6 py-4 cursor-pointer hover:text-blue-500 text-xs text-gray-800 whitespace-nowrap"
                      >
                        {item.name}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-800 whitespace-nowrap">
                        {item.sensor}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-800 whitespace-nowrap">
                        {item.location}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-800 whitespace-nowrap">
                        {item.country}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-800 whitespace-nowrap">
                        {item.LastValue} cm
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-800 whitespace-nowrap">
                        {item.lastDate}
                      </td>
                      <td className="px-6 py-4 text-xs text-gray-800 text-center whitespace-nowrap">
                        {item.ElapsedTime}
                      </td>
                      <td className={`px-6 py-4 text-xs  text-center whitespace-nowrap ${item.feedLatency === "INACTIVE" ? "text-red-600": "text-gray-800"}`}>
                        {item.feedLatency} 
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
