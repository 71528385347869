const SimpleMap = ({maps}) => {
  return (
    // <div className="w-[100%] h-[100%] overflow-hidden">
      <iframe
      title="maps"
        width="100%"
       height="100%" 
      //  style="border:0"
        id="gmap_canvas"
        src={maps}
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    // </div>
  );
};

export default SimpleMap;

