import React from 'react';
import { Layout } from './component/molecules';

function App() {
  return (
    <div>
      <Layout />
    </div>
  );
}

export default App;





