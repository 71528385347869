import React from "react";
import { Route, Routes } from "react-router";
import { Home, PummaCanti, PummaList } from "../pages";
import { PummaMarinajambu, PummaPangandaran, PummaPanjang, PummaPetengoran } from "../pages/pumma";

const Index = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pumma" element={<PummaList />} />
        <Route path="/pumma-canti" element={<PummaCanti />} />
        <Route path="/pumma-gebang" element={<PummaPetengoran />} />
        <Route path="/pumma-panjang" element={<PummaPanjang />} />
        <Route path="/pumma-marina-jambu" element={<PummaMarinajambu />} />
        <Route path="/pumma-pangandaran" element={<PummaPangandaran />} />
      </Routes>
    </div>
  );
};

export default Index;
